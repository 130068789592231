/**
 * Init Datepick in search form for Check In
 */
function wpbc_init_datepick__search_check_in(){

	var datepick_params = {
		onSelect      : wpbc_search_check_in_selected,
		beforeShowDay : wpbc_search_apply_css_before_show_check_in,
		showOn        : 'focus',
		multiSelect   : 0,
		numberOfMonths: 1,
		stepMonths    : 1,
		prevText      : '&lsaquo;',
		nextText      : '&rsaquo;',
		dateFormat    : _wpbc.get_other_param( 'search_avy__dates_format' ),
		changeMonth   : false,
		changeYear    : false,
		minDate       : 0,
		maxDate       : _wpbc.get_other_param( 'calendars__max_monthes_in_calendar' ), 		//'1Y',
		// minDate	  : new Date(2020, 2, 1), maxDate: new Date(2020, 9, 31),             	// Ability to set any  start and end date in calendar
		showStatus      : false,
		multiSeparator  : ', ',
		closeAtTop      : false,
		firstDay        : _wpbc.get_other_param( 'calendars__first_day' ),
		gotoCurrent     : false,
		hideIfNoPrevNext: true,
		useThemeRoller  : false,
		mandatory       : true,
		_mainDivId      : ['datepick-div', 'ui-datepicker-div', 'widget_wpdev_booking']
	};

	var search_check_in_date = '';

	var url_params = location.href.split( '?' );
	if ( url_params.length > 1 ){
		url_params = url_params[ 1 ].split( '&' );
		for ( var i = 0; i < url_params.length; i++ ){
			var one_param = url_params[ i ].split( '=' );
			if ( (one_param[ 0 ] === 'search_field__display_check_in') && ('' === search_check_in_date) ){
				search_check_in_date = one_param[ 1 ];
			}
			if ( one_param[ 0 ] === 'search_get__check_in_ymd' ){
				search_check_in_date = one_param[ 1 ];
			}
		}
	}
	if ( ('' !== search_check_in_date) && ('2100-01-01' !== search_check_in_date) ){
		datepick_params[ 'defaultDate' ] = jQuery.datepick.formatDate(
																		_wpbc.get_other_param( 'search_avy__dates_format' ),
																	 	jQuery.datepick.parseDate( 'yy-mm-dd', search_check_in_date )
																	);
		datepick_params[ 'showDefault' ] = true;
	}

	jQuery( '#search_field__display_check_in' ).datepick( datepick_params );
}

/**
 * Init Datepick in search form for Check Out
 */
function wpbc_init_datepick__search_check_out(){

	var datepick_params = {
		beforeShowDay : wpbc_search_apply_css_before_show_check_out,
		showOn        : 'focus',
		multiSelect   : 0,
		numberOfMonths: 1,
		stepMonths    : 1,
		prevText      : '&lsaquo;',
		nextText      : '&rsaquo;',
		dateFormat    : _wpbc.get_other_param( 'search_avy__dates_format' ),
		changeMonth   : false,
		changeYear    : false,
		minDate       : 0,
		maxDate       : _wpbc.get_other_param( 'calendars__max_monthes_in_calendar' ), 		//'1Y',
		// minDate	  : new Date(2020, 2, 1), maxDate: new Date(2020, 9, 31),             	// Ability to set any  start and end date in calendar
		showStatus      : false,
		multiSeparator  : ', ',
		closeAtTop      : false,
		firstDay        : _wpbc.get_other_param( 'calendars__first_day' ),
		gotoCurrent     : false,
		hideIfNoPrevNext: true,
		useThemeRoller  : false,
		mandatory       : true,
		_mainDivId      : ['datepick-div', 'ui-datepicker-div', 'widget_wpdev_booking']
	};

	var search_check_out_date = '';

	var url_params = location.href.split( '?' );
	if ( url_params.length > 1 ){
		url_params = url_params[ 1 ].split( '&' );
		for ( var i = 0; i < url_params.length; i++ ){
			var one_param = url_params[ i ].split( '=' );
			if ( (one_param[ 0 ] === 'search_field__display_check_out') && ('' === search_check_out_date) ){
				search_check_out_date = one_param[ 1 ];
			}
			if ( one_param[ 0 ] === 'search_get__check_out_ymd' ){
				search_check_out_date = one_param[ 1 ];
			}
		}
	}
	if ( ('' !== search_check_out_date) && ('2100-01-01' !== search_check_out_date) ){
		datepick_params[ 'defaultDate' ] = jQuery.datepick.formatDate(
																		_wpbc.get_other_param( 'search_avy__dates_format' ),
																	 	jQuery.datepick.parseDate( 'yy-mm-dd', search_check_out_date )
																	);
		datepick_params[ 'showDefault' ] = true;
	}

	jQuery( '#search_field__display_check_out' ).datepick( datepick_params );
}

	/**
	 * Set-up 	available | unavailable 	dates in Check In calendar  in search  form
	 * @param date
	 * @returns {(boolean|string)[]}
	 */
	function wpbc_search_apply_css_before_show_check_in( date ){

		var today_date = new Date( _wpbc.get_other_param( 'today_arr' )[ 0 ], (parseInt( _wpbc.get_other_param( 'today_arr' )[ 1 ] ) - 1), _wpbc.get_other_param( 'today_arr' )[ 2 ], 0, 0, 0 );								// Today JS_Date_Obj.
		var class_day = wpbc__get__td_class_date( date );															 	// '1-9-2023'

		// Unavailable Weekdays
		for ( var i = 0; i < _wpbc.get_other_param( 'availability__week_days_unavailable' ).length; i++ ){
			if ( date.getDay() == _wpbc.get_other_param( 'availability__week_days_unavailable' )[ i ] ){
				return [false, 'cal4date-' + class_day + ' date_user_unavailable'];
			}
		}
		// Unavailable dates relative to  Today date
		if (
			   ( wpbc_dates__days_between( date, today_date ) < parseInt( _wpbc.get_other_param( 'availability__unavailable_from_today' ) ))
			|| (
				   ( parseInt( '0' + parseInt( _wpbc.get_other_param( 'availability__available_from_today' ) ) ) > 0 )
				&& ( wpbc_dates__days_between( date, today_date ) > parseInt( '0' + parseInt( _wpbc.get_other_param( 'availability__available_from_today' ) ) ) )
			)
		){
			return [false, 'cal4date-' + class_day + ' date_user_unavailable'];
		}

		// Available
		return [true, 'cal4date-' + class_day + ' date_available '];
	}

	/**
	 * After Check In day selected in Search Form
	 * @param date
	 */
	function wpbc_search_check_in_selected( date ) {

        // Parse a string value into a date object
        var search_check_in_date = jQuery.datepick.parseDate( _wpbc.get_other_param( 'search_avy__dates_format' ) , jQuery( '#search_field__display_check_in' ).val() );

        var date_to_check = new Date();
        date_to_check.setFullYear( search_check_in_date.getFullYear(), search_check_in_date.getMonth(), search_check_in_date.getDate() );

		var startDay;
		var string_date;

        if (
			   ( 'fixed' == _wpbc.get_other_param( 'calendars__days_select_mode' ) )
			&& (      -1 != _wpbc.get_other_param( 'calendars__fixed__week_days__start' ) )
		){

            startDay = wpbc_get_abs_closest_value_in_arr( date_to_check.getDay(), _wpbc.get_other_param( 'calendars__fixed__week_days__start' ) );
            date_to_check.setDate( date_to_check.getDate() - (date_to_check.getDay() - startDay) );

            string_date = jQuery.datepick.formatDate( _wpbc.get_other_param( 'search_avy__dates_format' ), date_to_check );        // Format a date object into a string value.

            jQuery( '#search_field__display_check_in' ).val( string_date );
        }
        if (  (  _wpbc.get_other_param( 'calendars__days_select_mode' ) == 'dynamic' ) && ( _wpbc.get_other_param( 'calendars__dynamic__week_days__start' ) != -1 )  ){

            startDay = wpbc_get_abs_closest_value_in_arr( date_to_check.getDay(), _wpbc.get_other_param( 'calendars__dynamic__week_days__start' ) );
            date_to_check.setDate( date_to_check.getDate() - (date_to_check.getDay() - startDay) );

            string_date = jQuery.datepick.formatDate( _wpbc.get_other_param( 'search_avy__dates_format' ), date_to_check );        // Format a date object into a string value.
            jQuery( '#search_field__display_check_in' ).val( string_date );
        }

        if ( document.getElementById( 'search_field__display_check_out' ) !== null ){

            var start_bk_month_4_check_out = jQuery.datepick.parseDate( _wpbc.get_other_param( 'search_avy__dates_format' ), jQuery( '#search_field__display_check_in' ).val() );
            var myDate = new Date();
            myDate.setFullYear( start_bk_month_4_check_out.getFullYear(), start_bk_month_4_check_out.getMonth(), start_bk_month_4_check_out.getDate() );

            var days_interval = wpbc_get_min_days_selection();
            if ( days_interval > 0 ) days_interval--;
            myDate.setDate( myDate.getDate() + days_interval );

            string_date = jQuery.datepick.formatDate( _wpbc.get_other_param( 'search_avy__dates_format' ), myDate );        // Format a date object into a string value.
            jQuery( '#search_field__display_check_out' ).val( string_date );

            // Auto open Check Out calendar selection   																//FixIn: 10.0.0.39
            if ( 'On' === _wpbc.get_other_param( 'search_avy__auto_open_checkout' ) ){
                setTimeout(function(){
                    jQuery( '#search_field__display_check_out' ).trigger( 'focus' );
                }, 100);
            }
        }
    }

	/**
	 * Set-up 	available | unavailable 	dates in Check Out calendar  in search  form
	 * @param date
	 * @returns {(boolean|string)[]}
	 */
    function wpbc_search_apply_css_before_show_check_out(date){

		var today_date = new Date( _wpbc.get_other_param( 'today_arr' )[ 0 ], (parseInt( _wpbc.get_other_param( 'today_arr' )[ 1 ] ) - 1), _wpbc.get_other_param( 'today_arr' )[ 2 ], 0, 0, 0 );								// Today JS_Date_Obj.
		var class_day = wpbc__get__td_class_date( date );															 	// '1-9-2023'

		// Unavailable Weekdays
		for ( var i = 0; i < _wpbc.get_other_param( 'availability__week_days_unavailable' ).length; i++ ){
			if ( date.getDay() == _wpbc.get_other_param( 'availability__week_days_unavailable' )[ i ] ){
				return [false, 'cal4date-' + class_day + ' date_user_unavailable'];
			}
		}
		// Unavailable dates relative to  Today date
		if (
			   ( wpbc_dates__days_between( date, today_date ) < parseInt( _wpbc.get_other_param( 'availability__unavailable_from_today' ) ))
			|| (
				   ( parseInt( '0' + parseInt( _wpbc.get_other_param( 'availability__available_from_today' ) ) ) > 0 )
				&& ( wpbc_dates__days_between( date, today_date ) > parseInt( '0' + parseInt( _wpbc.get_other_param( 'availability__available_from_today' ) ) ) )
			)
		){
			return [false, 'cal4date-' + class_day + ' date_user_unavailable'];
		}
		// ---------------------

		var additional_class = 'date_available ';
        if (
               ( document.getElementById( 'search_field__display_check_in' ) != null )
            && ( document.getElementById( 'search_field__display_check_in' ).value != '' )
        ){

			// Parse a string value into a date object
			var checkInDate = jQuery.datepick.parseDate( _wpbc.get_other_param( 'search_avy__dates_format' ), jQuery( '#search_field__display_check_in' ).val() );

			var days_interval = wpbc_get_min_days_selection();
			if ( days_interval > 0 ) days_interval--;
			checkInDate.setDate( checkInDate.getDate() + days_interval );

			var checkOutDate = jQuery.datepick.parseDate( _wpbc.get_other_param( 'search_avy__dates_format' ), jQuery( '#search_field__display_check_in' ).val() );
			var days_interval_max = wpbc_get_max_days_selection();
			if ( days_interval_max > 0 ) days_interval_max--;
			checkOutDate.setDate( checkOutDate.getDate() + days_interval_max );

			if ( (checkInDate <= date) && (checkOutDate >= date) ){
				return [true, 'cal4date-' + class_day + ' ' + additional_class + ' ']; // Available
			} else {
				return [false, 'cal4date-' + class_day + ' date_user_unavailable'];										// return [false, ''];  // Unavailable
			}

        } else {
            return [true, 'cal4date-' + class_day + ' ' + additional_class + ' '];      // Available
        }
    }

		/**
		 * Get Minimum number of Days to Select from 	Settings General page
		 * @returns {number}
		 */
		function wpbc_get_min_days_selection(){																			//FixIn: 8.6.1.21

			if ( 'dynamic' === _wpbc.get_other_param( 'calendars__days_select_mode' ) ){
				return parseInt( _wpbc.get_other_param( 'calendars__dynamic__days_min' ) );
			}
			if ( 'fixed' === _wpbc.get_other_param( 'calendars__days_select_mode' ) ){
				return parseInt( _wpbc.get_other_param( 'calendars__fixed__days_num' ) );
			}
			return 0;
		}

		/**
		 * Get Maximum number of Days to Select from 	Settings General page
		 * @returns {number}
		 */
		function wpbc_get_max_days_selection(){																			//FixIn: 9.9.0.44
			if ( 'dynamic' === _wpbc.get_other_param( 'calendars__days_select_mode' ) ){
				return parseInt( _wpbc.get_other_param( 'calendars__dynamic__days_max' ) );
			}
			if ( 'fixed' === _wpbc.get_other_param( 'calendars__days_select_mode' ) ){
				return parseInt( _wpbc.get_other_param( 'calendars__fixed__days_num' ) );
			}
			return 365;
		}

// =====================================================================================================================

/**
 * Select 'search_quantity' | 'location' | ... options and Search Filters (previously custom fields).
 * Params in search form, after page loaded depends on GET params
 */
function wpbc_search_avy__auto_select_fields_in_search_form__if_params_in_url(){

	var url_params = new URLSearchParams( window.location.search );

	if (
		( url_params.has( 'search_get__custom_params' ) )
	){

		var search_get__custom_params =  url_params.get( 'search_get__custom_params' );

		// Convert back.     Some systems do not like symbol '~' in URL, so  we need to replace to  some other symbols
		search_get__custom_params = search_get__custom_params.replaceAll( '_^_', '~' );

		var search_fields_arr = wpbc_auto_fill_search_fields__parse( search_get__custom_params )

		for ( let i = 0; i < search_fields_arr.length; i++ ){

			if (
					   wpbc_in_array( ['text', 'select-one','selectbox-one', 'select-multiple','selectbox-multiple', 'radio', 'checkbox']  , search_fields_arr[ i ][ 'type' ] )
				&& ( ! wpbc_in_array( ['search_field__display_check_in', 'search_field__display_check_out', /*'search_quantity',*/ 'search_field__extend_search_days' ] , search_fields_arr[ i ]['name'] ) )
			){
				var cleaned_name  =  search_fields_arr[ i ]['name']  ;
				var cleaned_value =  search_fields_arr[ i ]['value'] ;

				jQuery( '[name="' + cleaned_name + '"]' ).val( cleaned_value ).trigger( 'change' );
			}
		}
	}

	url_params.forEach( ( value, key ) => {
		if ( key.startsWith( 'booking_' ) ){
			jQuery( '[name="' + key + '"]' ).val( value ).trigger( 'change' );
		}
	} );
}

/** Icons Trigger to open calendars, if clicked on Calendar Icons */
jQuery( '.wpbc_icn_check_in' ).on( 'click', function ( event ){
	jQuery( '#search_field__display_check_in' ).trigger( 'focus' );
});
jQuery( '.wpbc_icn_check_out' ).on( 'click', function ( event ){
	jQuery( '#search_field__display_check_out' ).trigger( 'focus' );
});


/**
 *  Disable search button
 */
function wpbc_search_form__disable_submit_button(){

	//FixIn: 9.9.0.45
	// jQuery( '#booking_search_form .search_booking' ).prop( 'disabled', true );
	jQuery( '#booking_search_form .search_booking' ).attr( 'saved-button-color', jQuery( '#booking_search_form .search_booking' ).css( 'color' ) );
	jQuery( '#booking_search_form .search_booking' ).css( 'color', '#ccc' );
	jQuery( '#booking_search_form .search_booking' ).css( 'cursor', 'not-allowed' );
}

/**
 * Enable search button
 */
function wpbc_search_form__enable_submit_button(){

	//FixIn: 9.9.0.45
	jQuery( '#booking_search_form .search_booking' ).prop( 'disabled', false );
	if ( undefined != jQuery( '#booking_search_form .search_booking' ).attr( 'saved-button-color' ) ){     //FixIn: 9.5.1.2
		jQuery( '#booking_search_form .search_booking' ).css( 'color', jQuery( '#booking_search_form .search_booking' ).attr( 'saved-button-color' ) );
	}
	jQuery( '#booking_search_form .search_booking' ).css( 'cursor', 'pointer' );
}


/**
 * Get as String the all Values from HTML Search Form fields. 		E.g.: 'select^search_quantity^2~....'
 *
 * @param search_form		- HTML Dom Form element: this.form
 * @returns {string}		- 'select^search_quantity^2~....'
 */
function wpbc_search_form__get_all_input_fields_data( search_form ){												//FixIn: 7.1.2.9

	var all_paramas = '';
	var inp_el;
	var inp_val;

	for ( var i = 0; i < search_form.length; i++ ){

		if ( (search_form[ i ].type == 'checkbox') && (!search_form[ i ].checked) ){
			continue;
		}

		inp_el = search_form[ i ].type + "^" + search_form[ i ].name + "^";

		inp_val = jQuery( search_form[ i ] ).val();

		if ( Array.isArray( inp_val ) ){
			jQuery.each( inp_val, function ( index ){
				all_paramas += inp_el + inp_val[ index ] + "~";
			} );
		} else {
			all_paramas += inp_el + inp_val + "~";
		}
	}

	return all_paramas;
}

/**
 * Get Date value Parsed from  Search Date format  to  '2024-05-06' format
 *
 * @param html_id		'#search_field__display_check_in' | '#search_field__display_check_out'
 */
function wpbc_search_form__get_parsed_search_date__as_ymd( html_id ){

	var booking_search_date = '';
	if ( jQuery( html_id ).length ){
		booking_search_date = jQuery( html_id ).val();
	}

	// Set JS  date to  some Future date,  that will be available for sure
	var search_js_date = new Date( '2100-01-01' );

	// Parse a string value into a date object
	if ( '' !== booking_search_date ){

		search_js_date = jQuery.datepick.parseDate( _wpbc.get_other_param( 'search_avy__dates_format' ), booking_search_date );

		// Format a date object into a string value.
		var search_string_date = jQuery.datepick.formatDate( 'yy-mm-dd', search_js_date );

		return search_string_date;

	} else {
		return '2100-01-01'; 				//FixIn: 10.2.2.1
	}
}


// ---------------------------------------------------------------------------------------------------------------------
// Search Submit  -  New Page
// ---------------------------------------------------------------------------------------------------------------------

/**
 * Prepare for submit Search Form  to New Page
 *
 * @param search_form			HTML Dom  form:  this.form
 * @param wpdbc_active_locale	string locale: 	'es_ES'
 * @returns {boolean}
 */
function wpbc_search_form_click_new_page( search_form, wpdbc_active_locale ){																//FixIn: 8.6.1.21

	if ( 'Off' != _wpbc.get_other_param( 'search_avy__warning__dates_required_enabled' ) ){
		if ( ('' == jQuery( '#search_field__display_check_in' ).val()) || ('' == jQuery( '#search_field__display_check_out' ).val()) ){
			alert( _wpbc.get_other_param( 'search_avy__warning__dates_required_warning' ) );
			return false;
		}
	}
	wpbc_search_form__disable_submit_button();

	// -------------------------------------------------------------------------------------------------------------
	// Get Search Fields:
	// -------------------------------------------------------------------------------------------------------------
	var param__check_in_ymd = wpbc_search_form__get_parsed_search_date__as_ymd( '#search_field__display_check_in' );			//FixIn: 9.5.3.4
	jQuery( '[name="search_get__check_in_ymd"]' ).val( param__check_in_ymd );          	// in format  2024-05-06
	// -------------------------------------------------------------------------------------------------------------
	var param__check_out_ymd = wpbc_search_form__get_parsed_search_date__as_ymd( '#search_field__display_check_out' );
	jQuery( '[name="search_get__check_out_ymd"]' ).val( param__check_out_ymd );        	// in format  2024-05-06
	// -------------------------------------------------------------------------------------------------------------
	var param__search_quantity = (jQuery( "[name='search_quantity']" ).length > 0)
								? jQuery( "[name='search_quantity']" ).val()
								: 1;
	jQuery( '[name="search_get__quantity"]' ).val( param__search_quantity );
	var param__search_time = (jQuery( "[name='search_time']" ).length > 0)
								? jQuery( "[name='search_time']" ).val()
								: '';
	jQuery( '[name="search_get__time"]' ).val( param__search_time );
	// -------------------------------------------------------------------------------------------------------------
	var param__users_id = (jQuery( '#search_field__users_id' ).length)
									 ? jQuery( '#search_field__users_id' ).val()
									 : '';
	jQuery( '[name="search_get__users_id"]' ).val( param__users_id );
	// -------------------------------------------------------------------------------------------------------------
	var param__extended_days = (jQuery( "input[name='search_field__extend_search_days']:checked" ).length > 0)
								? jQuery( "input[name='search_field__extend_search_days']" ).val()
								: 0;
	jQuery( '[name="search_get__extend"]' ).val( param__extended_days );
	// -------------------------------------------------------------------------------------------------------------
	// Get as String the all Values from HTML Search Form fields. 		E.g.: 'select^search_quantity^2~....'
	var param__all_as_str = wpbc_search_form__get_all_input_fields_data( search_form );
	param__all_as_str = param__all_as_str.replaceAll( '~', '_^_' );		// Some systems do not like symbol '~' in URL, so  we need to replace to  some other symbols

	jQuery( '[name="search_get__custom_params"]' ).val( param__all_as_str );          			// in format  'select^search_quantity^2~....'
	// -------------------------------------------------------------------------------------------------------------

	return  true;
}


// -------------------------------------------------------------------------------------------------------------
// Search Submit  -  Ajax
// -------------------------------------------------------------------------------------------------------------

/**
 * Submit Search Ajax request,  and show Loading spinner
 *
 * @param search_form           DOM Form -> this.form
 * @param wpdev_active_locale   Locale   -> 'es_ES'
 */
function wpbc_search_form_click_ajax( search_form, wpdev_active_locale ){

	if ( 'Off' != _wpbc.get_other_param( 'search_avy__warning__dates_required_enabled' ) ){
		if ( ('' === jQuery( '#search_field__display_check_in' ).val()) || ('' === jQuery( '#search_field__display_check_out' ).val()) ){
			alert( _wpbc.get_other_param( 'search_avy__warning__dates_required_warning' ) );
			return false;
		}
	}

	// ---------------------------------------------------------------------
	// Show Loading Spinner
	// ---------------------------------------------------------------------
	//// :: Old ::
	//document.getElementById('booking_search_results' ).innerHTML = '<div style="height:20px;width:100%;text-align:center;margin:15px auto;"><img  style="vertical-align:middle;box-shadow:none;width:14px;" src="'+_wpbc.get_other_param( 'url_plugin' )+'/assets/img/ajax-loader.gif"><//div>';
	////:: Big Loading Circle ::
	//jQuery( '#booking_search_results' ).html( '<div class="wpbc_booking_form_spin_loader" style="position: relative;margin-top: 70px;"><div class="wpbc_spins_loader_wrapper"><div class="wpbc_spins_loader"></div></div></div>' );

	////:: Mini Loading Circle ::       //FixIn: 10.0.0.38
	jQuery( '#booking_search_results' ).html( '<div class="wpbc_booking_form_spin_loader" style="position: relative;margin-top: 50px;"><div class="wpbc_spins_loader_wrapper"><div class="wpbc_spins_loader_mini"></div></div></div>' );

	wpbc_ajax_search_submit( search_form, wpdev_active_locale );
}


	/**
	 * Send Ajax Search Availability Request
	 *
	 * @param search_form			HTML Dom  form:  this.form
	 * @param wpdbc_active_locale	string locale: 	'es_ES'
	 */
    function wpbc_ajax_search_submit( search_form, wpdbc_active_locale ) {           	//FixIn: 6.0.1.1

		// Disable Submit button
		wpbc_search_form__disable_submit_button();										//FixIn: 9.9.0.45
        jQuery( '#booking_search_form .search_booking' ).prop( 'disabled', true );

		// Clear search results
		jQuery( '.booking_search_ajax_container' ).remove();

		// -------------------------------------------------------------------------------------------------------------
		// Get Search Fields:
		// -------------------------------------------------------------------------------------------------------------

		// Get as String the all Values from HTML Search Form fields. 		E.g.: 'select^search_quantity^2~....'
		var param__all_as_str    = wpbc_search_form__get_all_input_fields_data( search_form );

		var param__check_in_ymd  = wpbc_search_form__get_parsed_search_date__as_ymd( '#search_field__display_check_in' );		//FixIn: 8.6.1.21
		var param__check_out_ymd = wpbc_search_form__get_parsed_search_date__as_ymd( '#search_field__display_check_out' );

		var param__users_id = (jQuery( '#search_field__users_id' ).length)
										 ? jQuery( '#search_field__users_id' ).val()
										 : '';
		var param__extended_days = (jQuery( "input[name='search_field__extend_search_days']:checked" ).length > 0)
									? jQuery( "input[name='search_field__extend_search_days']" ).val()
									: 0;
		var param__search_quantity = (jQuery( "[name='search_quantity']" ).length > 0)
									? jQuery( "[name='search_quantity']" ).val()
									: 1;
		var param__search_time = (jQuery( "[name='search_time']" ).length > 0)
									? jQuery( "[name='search_time']" ).val()
									: '';
		// -------------------------------------------------------------------------------------------------------------
		jQuery.ajax({
			url    : wpbc_url_ajax,
			type   :'POST',
			success: function ( data, textStatus ){
				if ( textStatus == 'success' ){

					if (
						    ( 'undefined' !== typeof (data) )
					     && ( 'undefined' !== typeof (data.ajx_data_search_content) )
					){
						jQuery( '#booking_search_results' ).html( data.ajx_data_search_content );// '<div class="wpbc_booking_form_spin_loader"
						// jQuery( '#booking_search_ajax' ).html( data.ajx_data_search_content );
						// jQuery( "#booking_search_ajax" ).after( jQuery( "#booking_search_ajax .booking_search_ajax_container" ) );
						// jQuery( "#booking_search_ajax" ).hide();
					} else {
						console.log( ' :: SEARCH RESPONSE ERROR :: ', data );
						var jq_node = jQuery( '#booking_search_results' );
						// Show Message
						wpbc_front_end__show_message( ' :: SEARCH RESPONSE ERROR :: <br>' + JSON.stringify( data ).replace( /\n/g, "<br />" ),
														{   'type'     : 'warning',
															'show_here': {'jq_node': jq_node, 'where': 'after'},
															'is_append': true,
															'style'    : 'text-align:left;',
															'delay'    : 30000
														} );
						jQuery( '#booking_search_results .wpbc_booking_form_spin_loader').remove();
					}
					wpbc_search_form__enable_submit_button();
				}
			},
			error: function ( XMLHttpRequest, textStatus, errorThrown ){ console.log(  'WPBC Error :: Ajax search Error status:' + textStatus , XMLHttpRequest ); alert( XMLHttpRequest.status + ' ' + XMLHttpRequest.statusText ); if ( XMLHttpRequest.status == 500 ){  alert( 'Please check at this page according this error:' + ' https://wpbookingcalendar.com/faq/#ajax-sending-error' );  } },
			data: {
				action                    : 'BOOKING_SEARCH',

				search_ajx__check_in_ymd  : param__check_in_ymd,
				search_ajx__check_out_ymd : param__check_out_ymd,
				search_ajx__quantity      : param__search_quantity,
				search_ajx__time      	  : param__search_time,

				search_ajx__custom_params : param__all_as_str,
				search_ajx__extend        : param__extended_days,
				search_ajx__users_id      : param__users_id,

				wpdev_active_locale       : wpdbc_active_locale,
				wpbc_nonce                : document.getElementById( 'wpbc_search_nonce' ).value
			}
		});
	}

